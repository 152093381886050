import React from 'react';

import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import PricingCards from '../../components/pricing-cards-api';
import PricingCard from '../../components/pricing-cards-api/pricing-card';
import Layout from '../../components/layout/layout';

import paperPlane from '../../img/pricing/paper-plane.svg';
import balloon from '../../img/pricing/hot-air-balloon.svg';
import airplane from '../../img/pricing/airplane.svg';
import rocket from '../../img/pricing/rocket.svg';

import './style.scss';

const WhoisApi: React.FC = (): JSX.Element => {
  return (
    <Layout>
      <PageHero title="Technology Stack API - Price Plans" subtitle="" />
      <Section>
        <div className="col-12 d-block mb_20 teckstack-api" style={{maxWidth: '1200px', width: '100%'}}>
          <h5>Tech Stack API - Get the full technology stack of any website as a service</h5>

          <p>
            The Hexometer Tech Stack API provides fresh technology use data for your business and creates new leads. The
            Hexometer Tech Stack API service gets all the technology stack including SAAS, Apps, Plugins and scripts use
            for a provided domain.
          </p>

          <div className="table-block">
            <PricingCards
              QueriesPerMonth="QUERY_PER_MONTH_WHOIS_API"
              NumberOfTLDs="NUMBER_OF_TLDS_WHOIS_API"
              OutputFormats="OUTPUT_FORMATS_WHOIS_API"
              QueryLimitsPerMinute="QUERY_LIMITS_PER_MINUTE_WHOIS_API"
            >
              <PricingCard
                title="PRICE_TITLE_BASIC_WHOIS_API"
                NumberOfTLDs="NUMBER_OF_TLDS_BASIC_WHOIS_API"
                OutputFormats="OUTPUT_FORMATS_BASIC_WHOIS_API"
                QueryLimitsPerMinute="QUERY_LIMITS_PER_MINUTE_BASIC_WHOIS_API"
                priceMonth="25"
                priceYearPerMonth="20"
                priceYear="250"
                QueriesPerMonth="QUERIES_PER_MONTH_BASIC_WHOIS_API"
                packageCode="free"
                img={paperPlane}
                alt="paperPlane"
              />
              <PricingCard
                title="PRICE_TITLE_STANDART_WHOIS_API"
                NumberOfTLDs="NUMBER_OF_TLDS_STANDARD_WHOIS_API"
                OutputFormats="OUTPUT_FORMATS_STANDARD_WHOIS_API"
                QueryLimitsPerMinute="QUERY_LIMITS_PER_MINUTE_STANDARD_WHOIS_API"
                priceMonth="40"
                priceYearPerMonth="33"
                priceYear="400"
                QueriesPerMonth="QUERIES_PER_MONTH_STANDARD_WHOIS_API"
                packageCode="basic"
                img={balloon}
                alt="balloon"
              />
              <PricingCard
                title="PRICE_TITLE_ADVANCED_WHOIS_API"
                NumberOfTLDs="NUMBER_OF_TLDS_ADVANCED_WHOIS_API"
                OutputFormats="OUTPUT_FORMATS_ADVANCED_WHOIS_API"
                QueryLimitsPerMinute="QUERY_LIMITS_PER_MINUTE_ADVANCED_WHOIS_API"
                priceMonth="75"
                priceYearPerMonth="62.5"
                priceYear="750"
                QueriesPerMonth="QUERIES_PER_MONTH_ADVANCED_WHOIS_API"
                packageCode="andvanced"
                img={airplane}
                alt="airplane"
                pabbly
              />
              <PricingCard
                title="PRICE_TITLE_ADVANCED_PLUS_WHOIS_API"
                NumberOfTLDs="NUMBER_OF_TLDS_ADVANCED_PLUS_WHOIS_API"
                OutputFormats="OUTPUT_FORMATS_ADVANCED_PLUS_WHOIS_API"
                QueryLimitsPerMinute="QUERY_LIMITS_PER_MINUTE_ADVANCED_PLUS_WHOIS_API"
                priceMonth="130"
                priceYearPerMonth="108"
                priceYear="1300"
                QueriesPerMonth="QUERIES_PER_MONTH_ADVANCED_PLUS_WHOIS_API"
                packageCode="andvanced+"
                img={rocket}
                alt="rocket"
              />
            </PricingCards>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default WhoisApi;
